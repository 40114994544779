/* You can add global styles to this file, and also import other style files */

// $Navy         : 
// linear-gradient(
//     296deg,
//     rgba(186, 30, 158.83, 0.86) 0%,
//     #d324a0 37%,
//     #d324a0 60%,
//     #751eba 100%
//   );

$gra : rgba(4, 39, 194, 0.541);  
$Navy: #ffffff;
$LightNavy    : #fff;
 
$LightestNavy : #303C55;
$Slate        : rgb(105, 101, 101);
$LightSlate   : #2724d3;
$LightSlateblack   : #751eba;
$LightestSlate: #d324a0;
$White        : #e6f1ff;
$Green        : #fff;
$GreenOpacity : rgba(100,255,218,0.07);
$Blue         : #0056b3;

$MainFont: 'Amaranth', sans-serif !important;
$CodeFont: 'Amaranth', sans-serif !important
